<template>
  <div>
    <!-- table -->
    <vue-good-table
      class="mt-3"
      mode="remote"
      :is-loading="isLoading"
      :columns="columns"
      :rows="rows"
      :rtl="directionIsRTL"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
      }"
      style-class="vgt-table striped"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Actions -->
        <div
          v-if="props.column.name === 'Actions'"
          cols="12"
          md="2"
          class="d-flex vgt-center-align"
        >
          <b-button
            v-if="canEditDeleteResourceCategory(props.row)"
            variant="outline-none"
            @click="editResourceCategory(props.row.id)"
          >
            <feather-icon
              icon="EditIcon"
              size="20"
            />
          </b-button>

          <b-button
            v-if="canEditDeleteResourceCategory(props.row)"
            variant="outline-none"
            @click="deleteResourceCategory(props.row.id)"
          >
            <feather-icon
              icon="TrashIcon"
              size="20"
            />
          </b-button>
        </div>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <table-pagination
          :per-page="perPage"
          :total="total"
          @perPageChanged="
            (value) => props.perPageChanged({ currentPerPage: value })
          "
          @pageChanged="(value) => props.pageChanged({ currentPage: value })"
        />
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { BButton, VBToggle, VBModal} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import resourcesService from "@/services/resourcesService";
import {
  GOODTABLE_ISO_DATETIME_INPUT_FORMAT, 
  GOODTABLE_LOCALISED_DATE_FORMAT,
  GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
  PRIMARY_DATE_FORMAT
} from "@/libs/utils";
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import useAomTableConfig from "@aom-core/useAomTableConfig.js";

export default {
  components: {
    VueGoodTable,
    BButton,
    TablePagination,
  },
  directives: {
    "b-modal": VBModal,
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      element: {},
      dir: false,
      columns: [
        {
          label: "Category Name",
          field: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Date Created",
          field: "created_at",
          filterOptions: {
            enabled: false,
            placeholder: "Search Date",
            filterFn: this.dateFilter
          },
          tdClass: "search-date-selector",
          type: 'date',
          dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
          dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT
        },
        {
          name: "Actions",
          field: "action",
          width: "12em",
        },
      ],
      columnFilters: [],
      sort: [],
      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
  },
  created() {
    this.loadItems();
  },
  mounted() {
    const inputs = document.getElementsByName("vgt-created_at");
    inputs.forEach(function (input) {
      flatPickr(input, {
        enableTime: false,
        dateFormat: GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT, 
        allowInput: true,
        altInput: true,
        altFormat: PRIMARY_DATE_FORMAT
      });
    });
  },
  methods: {
    canEditDeleteResourceCategory(resource) {
      return resource.program_id !== null;
    },
    dateFilter(date, filterString) {
      return (date = Date.parse(date) >= Date.parse(filterString));
    },

    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of ["name", "created_at"]) {
        if (params.columnFilters[col]) {
          columnFilters.push({
            field: col,
            value: params.columnFilters[col],
          });
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },

    async loadItems() {
      try {
        this.isLoading = true;
        const response = await resourcesService.getProgramResourceCategoriesList(this.$route.params.id, {
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });
        this.total = response.data.total;
        this.rows = response.data.items.map(item => ({
          name: item.name,
          program_id: item.program_id,
          created_at: item.created_at,
          id: item.id
        }));
      } catch (e) {
        this.$toast(makeErrorToast("Resource categories list not loaded."));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },

    editResourceCategory(id) {
      this.$nextTick(()=> {
        this.$emit('edit', id);
      });
      this.$root.$emit("bv::toggle::collapse", "edit-resource-right");
    },

    async deleteResourceCategory(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete this resource category? This action cannot be undone",
          {
            title: "Delete Resource Category",
            size: "sm",
            okVariant: "primary",
            okTitle: "Delete",
            cancelTitle: "Cancel",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async value => {
          if (value) {
            try {
              const response = await resourcesService.deleteProgramResourceCategory(this.$route.params.id, id);

              if (response) {
                this.$toast(makeSuccessToast("Resource category deleted successfully."));
                this.loadItems();
              }
            } catch (e) {
              const { data } = e.response;
              this.$log.error(e);
              this.$toast(makeErrorToast(data.message));
            }
          }
        });
    },
    resourceCategoryUpdated() {
      this.loadItems();
    }
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();
    return {
      total, perPage, page
    };
  }
};
</script>

<style lang="scss" scoped>
.badge {
  font-weight: 400 !important;
}
table.vgt-table td {
  vertical-align: middle;
}
</style>

<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
</style>