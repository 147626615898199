<template>
  <validation-observer
    ref="observer"
    v-slot="{ handleSubmit }"
  >
    <aom-languages>
      <b-row>
        <b-col md="12">
          <validation-provider
            v-slot="validationContext"
            ref="name"
            :rules="{ required: true }"
            vid="name"
            name="Category Name"
          >
            <b-form-group
              label="Category Name"
              label-for="category-name"
            >
              <b-form-input
                id="name"
                v-model="name"
                placeholder="Category Name"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <!-- submit and reset -->
      <b-row class="mt-2">
        <b-col cols="12">
          <b-button
            type="reset"
            variant="outline-secondary"
            class="mr-1"
            @click="onReset()"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            @click="handleSubmit(onSubmit)"
          >
            <b-spinner
              v-if="isUpdating"
              small
            />
            <span
              v-if="isUpdating"
            > Updating...</span>
            <span v-else>Submit</span>
          </b-button>
        </b-col>
      </b-row>
    </aom-languages>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BSpinner
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import AomLanguages from "@aom-core/AomLanguages";
import resourcesService from "@/services/resourcesService";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { getValidationState } from "@/libs/utils";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    AomLanguages,
  },
  directives: {
    Ripple,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: "",
      isUpdating: false,
    };
  },
  methods: {
    async onSubmit() {
      try {
        this.isUpdating = true;

        const payload = {
          name: this.name
        };
        const response = await resourcesService.createProgramResourceCategory(this.$route.params.id, payload);
        if (response) {
          this.$emit("resourceCategoryAdded");
          this.$toast(makeSuccessToast("Resource category created successfully."));
          this.$emit("hiddenModal", false);
          this.$root.$emit("bv::toggle::collapse", "add-resource-right");
        }
      } catch (e) {
        this.$toast(makeErrorToast("Something went wrong. Resource category not saved."));
        this.$log.error(e);
      } finally {
        this.isUpdating = false;
      }
    },
    onReset() {
      this.name = "";
      this.$root.$emit("bv::toggle::collapse", "add-resource-right");
    },
  },
  setup() {
    return {
      getValidationState,
    };
  },
};
</script>
